import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Title } from "components/atoms/title";
import { Text } from "components/atoms/text";
import { Button } from "components/atoms/button";

import Loly from "assets/icons/brands/loly.svg";
import Baumer from "assets/icons/brands/baumer.svg";
import Loreal from "assets/icons/brands/loreal.svg";
import AWS from "assets/icons/brands/aws.svg";
import Epic from "assets/icons/brands/epic.svg";
import Google from "assets/icons/brands/google.svg";
import UNlogo from "assets/icons/brands/un.svg";
import Ledger from "assets/icons/brands/ledger.svg";
import Nodaleto from "assets/icons/brands/nodaleto.svg";
import JPGlogo from "assets/icons/brands/jpg.svg";
// isab marant, clarins, L'oreal, Baumer

const PartnersSectionContainer = styled.section`
  margin-bottom: 120px;
`;

const PartnersSectionWrapper = styled.div`
  margin-left: 60px;
  padding: 0 100px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
    margin-left: 0;
  }
`;

const TextContainer = styled.div`
  max-width: 762px;
`;

const PartnersBanner = styled.div`
  background: ${({ theme }) => theme.colors.gray["100"]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 80px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    background: none;
    padding: 50px 40px;
    flex-direction: column;

    & > img {
      margin-bottom: 40px;
    }
  }
`;

const HorizontalLine = styled.hr`
  display: none;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 66px;
`;

export const PartnersSection: FC = () => {
  const { t } = useTranslation();

  return (
    <PartnersSectionContainer>
      <PartnersSectionWrapper>
        <Title type="h2" fontSize="120" mb={40} mt={120}>
          {t("home.partners.title")}
        </Title>
        {/* <TextContainer>
          <Text mb={40} mbmobile={40}>
            {t("home.partners.description")}
          </Text>
        </TextContainer> */}
        {/* <ButtonContainer> */}
        {/* <Button href="/#contactUs">{t("navigationBar.cta")}</Button> */}
        {/* </ButtonContainer> */}
      </PartnersSectionWrapper>
      <PartnersBanner>
        <img src={Baumer} alt="" />
        <img src={UNlogo} alt="" width={80} />
        <img src={Google} alt="" width={60} />
        <img src={Ledger} alt="" width={100} />
        <img src={Nodaleto} alt="" width={100} />
        <img src={Epic} alt="" width={60} />
        <img src={Loly} alt="" width={160} />
        <img src={AWS} alt="" width={60} />
        <img src={JPGlogo} alt="" width={120} />
        <img src={Loreal} alt="" />
      </PartnersBanner>
      <PartnersSectionWrapper>
        <HorizontalLine />
      </PartnersSectionWrapper>
    </PartnersSectionContainer>
  );
};
