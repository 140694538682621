import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Title } from "components/atoms/title";
import { Text } from "components/atoms/text";
import { Button } from "components/atoms/button";
import { HorizontalBar } from "components/atoms/horizontalBar";

const DescriptionSectionContainer = styled.section`
  padding: 0 100px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

const DescriptionSectionWrapper = styled.div`
  margin-left: 60px;

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    margin-left: 0;
  }
`;

const TitleContainer = styled.div`
  max-width: 1000px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 66px;
`;

export const DescriptionSection: FC = () => {
  const { t } = useTranslation();

  return (
    <DescriptionSectionContainer>
      <DescriptionSectionWrapper>
        <TitleContainer>
          <Title type="h2" fontSize="120" mb={40} mt={120}>
            {t("home.description.title")}
          </Title>
        </TitleContainer>
        <Text mb={40} mbmobile={40}>
          {t("home.description.text")}
        </Text>
        <ButtonContainer>
          <Button href="https://tally.so/r/3NXoPW">
            {t("navigationBar.cta")}
          </Button>
        </ButtonContainer>
      </DescriptionSectionWrapper>
      <HorizontalBar />
    </DescriptionSectionContainer>
  );
};
