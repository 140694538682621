import React, { FC } from "react";
export { Head } from "src/utils/head";

import { Layout } from "components/template/layout";

import { VideoSection } from "./_home/_videoSection";
import { DescriptionSection } from "./_home/_descriptionSection";

import { ExperienceOneSection } from "./_home/_experienceOneSection";
import { ExperienceTwoSection } from "./_home/_experienceTwoSection";
import { ExperienceThreeSection } from "./_home/_experienceThreeSection";

import { PartnersSection } from "./_home/_partnersSection";
import { ContactSection } from "./_home/_contactSection2";

// import { OgpassSection } from "./_home/_ogpassSection";
// import { TiniesSection } from "./_home/_tiniesSection";
// import { DarmoSection } from "./_home/_darmoSection";

const IndexPage: FC = () => (
  <Layout>
    <VideoSection />

    <DescriptionSection />
    <PartnersSection />

    <ExperienceOneSection />
    <ExperienceTwoSection />
    <ExperienceThreeSection />
    <ContactSection />
  </Layout>
);

export default IndexPage;
