import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Text } from "components/atoms/text";
import { Title } from "components/atoms/title";
import { HorizontalBar } from "components/atoms/horizontalBar";
import { Button } from "components/atoms/button";

import HomeVideo from "assets/video/orsay.mp4";

const VideoSectionContainer = styled.section`
  /* height: calc(100vh - 20px); */
  /* margin-top: 80px; */
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    height: auto;
    margin-bottom: 40px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    margin-top: 80px;
  }
`;

const Video = styled.video`
  width: 100%;
`;

const TextContainer = styled.div`
  padding: 0 160px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

const CustomText = styled.span`
  font-family: "Post Grotesk Thin";
  letter-spacing: 0.05em;
  color: ${({ theme }) => theme.colors.gray["500"]};
`;

const TitleSectionWrapper = styled.div`
  margin-top: 0;
  margin-left: 160px;

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    margin-left: 0;
  }
`;

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
`;

export const ExperienceOneSection: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <VideoSectionContainer>
        <TitleSectionWrapper>
          <Title type="h2" fontSize="80" mb={40} mt={80}>
            {t("home.experience-1.title")}
          </Title>
        </TitleSectionWrapper>
        <Video autoPlay loop muted playsInline>
          <source src={HomeVideo} type="video/mp4" />
        </Video>
      </VideoSectionContainer>
      <TextContainer>
        <Title type="h4" fontSize="24" mobilefontsize="16" mb={40}>
          <Text>{t("home.experience-1.text")}</Text>
          <ButtonContainer>
            {t("home.experience-1.cta") !== "home.experience-1.cta" && (
              <Button href="https://lu.ma/societhydarmo">
                {t("home.experience-1.cta")}
              </Button>
            )}
          </ButtonContainer>
        </Title>
        <HorizontalBar />
      </TextContainer>
    </>
  );
};
