import React, { FC, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import base from "src/api/base";

import { Title } from "components/atoms/title";
import { Text } from "components/atoms/text";
import { Input } from "components/atoms/input";
import { Button } from "components/atoms/button";

const ContactSectionContainer = styled.section`
  margin-top: 40px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

export const ContactSection: FC = () => {
  const { t } = useTranslation();

  return (
    <ContactSectionContainer id="contactUs">
      <Title type="h5" mb={40} align="center" fontSize="48" mobilefontsize="30">
        {t("home.contact.title")}
      </Title>
      <>
        <Text mb={40} mbmobile={20} align="center">
          {t("home.contact.sendForm")}
        </Text>
        <Button href="https://tally.so/r/3NXoPW">
          {" "}
          {t("home.contact.form")}
        </Button>
      </>
    </ContactSectionContainer>
  );
};
