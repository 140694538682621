import React, { FC } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Title } from "components/atoms/title";
import { HorizontalBar } from "components/atoms/horizontalBar";

import HomeVideo from "assets/video/homeVideo.mp4";

const VideoSectionContainer = styled.section`
  height: calc(100vh - 80px - 20px);
  margin-top: 80px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    height: auto;
    margin-bottom: 40px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    margin-top: 80px;
  }
`;

const Video = styled.video`
  width: 100%;
`;

const VideoTextContainer = styled.div`
  position: absolute;
  bottom: 270px;
  left: 160px;
  max-width: 900px;
  user-select: none;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    max-width: 600px;
    bottom: 160px;
    left: 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    bottom: 80px;
    left: 40px;
  }
`;

const TextContainer = styled.div`
  padding: 0 100px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

const CustomText = styled.span`
  font-family: "Post Grotesk Thin";
  color: ${({ theme }) => theme.colors.gray["300"]};
`;

export const VideoSection: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <VideoSectionContainer>
        <Video autoPlay muted playsInline>
          <source src={HomeVideo} type="video/mp4" />
        </Video>
        <VideoTextContainer>
          <Title type="h2" fontSize="64" mobilefontsize="24" color="#fff">
            {t("home.video.title")}
          </Title>
        </VideoTextContainer>
      </VideoSectionContainer>
      <TextContainer>
        <Title type="h4" fontSize="24" mobilefontsize="16" mb={40}>
          <CustomText>{t("home.video.descriptionHighlight")}</CustomText>{" "}
          {t("home.video.description")}
        </Title>
        <HorizontalBar />
      </TextContainer>
    </>
  );
};
